





















































































































@import '~variables'

.dashboard
    .select-field
        font-size $fs-h3
        font-weight 700
        top 8px
        left 10px
    .type-selector
        margin 10px 10px 0px 10px
    .data
        padding 20px
        .data__row
            margin-left 10px
            padding 3px
        .data__label
            font-weight 700
            font-size $fs-h3
        .data__value
            font-size $fs-h3
    .graph-card
        margin-bottom $border-thick
        max-width 400px
        width 100%
        &:last-child
            margin-bottom 0
    .graph-card
        max-width 2000px
